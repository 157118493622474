import React from 'react';
import {
  Create,
  SelectInput,
  SimpleForm,
  NumberInput,
  DateInput,
  required,
  useGetList,
} from 'react-admin';

// supervisor will be the logged in user...so no need to add add a question
// specifying who is the supervisor

export default function HarvestCreate(props) {
  const productRes = useGetList('products', {}, {}, {}).data;
  const farmWorkerRes = useGetList('staff', {}, {}, { role: 'farm-worker' }).data;
  const farmRes = useGetList('farms', {}, {}, {}).data;
  const clerkRes = useGetList('staff', {}, {}, { role: 'foreman' }).data;

  const products = Object.values(productRes).map(({ id, name }) => ({ id, name }));

  const farmWorkers = Object.values(farmWorkerRes)
    .map(({ id, first_name: firstName, last_name: lastName }) => ({ id, name: `${firstName} ${lastName}` }));

  const farms = Object.values(farmRes)
    .map(({ id, farm_name: farmName }) => ({ id, name: farmName }));

  const clerks = Object.values(clerkRes)
    .map(({ id, first_name: firstName, last_name: lastName }) => ({ id, name: `${firstName} ${lastName}` }));

  return (
    <Create title="Add a new harvest" {...props}>
      <SimpleForm>
        <NumberInput label="Amount" source="amount" validate={required()} />
        <SelectInput label="Product" source="harvested_product" choices={products} validate={required()} />
        <SelectInput label="Farm" source="harvest_farm" choices={farms} validate={required()} />
        <SelectInput label="Farm Worker" source="harvesting_worker" choices={farmWorkers} validate={required()} />
        <SelectInput label="Recording Officer" source="clerk" choices={clerks} validate={required()} />
        <DateInput label="Date of Harvest" source="date_of_harvest" validate={required()} />
      </SimpleForm>
    </Create>
  );
}
