import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

export default function StaffCreate(props) {
  return (
    <Create title="Add a new Product" {...props}>
      <SimpleForm>
        <TextInput label="Product Name" source="name" validate={required()} />
        <TextInput label="Units" source="units" validate={required()} />
      </SimpleForm>
    </Create>
  );
}
