import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
} from 'react-admin';

export default function StaffList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <TextField source="farm_name" sortable />
        <NumberField source="size" sortable label="Size (acres)" />
      </Datagrid>
    </List>
  );
}
