import React from 'react';
import {
  Edit,
  SimpleForm,
  DateInput,
  NumberInput,
  required,
} from 'react-admin';

export default function HarvestEdit(props) {
  return (
    <Edit title="Edit Harvest Data" {...props}>
      <SimpleForm>
        <DateInput label="Date of Harvest" source="date_of_harvest" validate={required()} />
        <NumberInput label="Amount" source="amount" />
      </SimpleForm>
    </Edit>
  );
}
