import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  EditButton,
} from 'react-admin';

export default function StaffList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <TextField source="first_name" sortable />
        <TextField source="last_name" sortable />
        <TextField source="phone_number" sortable />
        <EmailField source="email" />
        <TextField source="role" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
