import React from 'react';
import {
  email,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

export default function StaffCreate(props) {
  return (
    <Create title="Add a new Staff Member" {...props}>
      <SimpleForm>
        <TextInput label="First Name" source="first_name" validate={required()} />
        <TextInput label="Last Name" source="last_name" validate={required()} />
        <TextInput label="Email Address" source="email" type="email" validate={email()} />
        <TextInput label="Phone Number" source="phone_number" />
        <SelectInput
          label="Role"
          source="role"
          choices={[
            { id: 'supervisor', name: 'Supervisor' },
            { id: 'farm-worker', name: 'Farm Worker' },
            { id: 'foreman', name: 'Foreman' },
          ]}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
}
