import feathers from '@feathersjs/client';
import { authClient, restClient } from 'ra-data-feathers';
import axios from 'axios';

const host = process.env.REACT_APP_API_URL || 'http://localhost:3030';

const feathersClient = feathers()
  .configure(feathers.rest(host).axios(axios))
  .configure(feathers.authentication({
    jwtStrategy: 'jwt',
    storage: window.localStorage,
    storageKey: 'token',
  }));

const authClientOptions = {
  storageKey: 'token',
  authenticate: { strategy: 'local' },
  permissionsField: 'permissions',
  permissionsKey: 'permissions',
};

export const dataProvider = restClient(feathersClient);
export const authProvider = authClient(feathersClient, authClientOptions);
