import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';

export default function StaffCreate(props) {
  return (
    <Create title="Add a New Farm" {...props}>
      <SimpleForm>
        <TextInput label="Farm Name" source="farm_name" validate={required()} />
        <TextInput label="Title Number" source="title_number" />
        <NumberInput label="Size(acres)" source="size" validate={required()} />
      </SimpleForm>
    </Create>
  );
}
