import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  ReferenceField,
  NumberField,
  TextField,
  EditButton,
} from 'react-admin';

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
const FullnameField = ({ record = {} }) => (
  <span>{record.first_name} {record.last_name}</span>
);

export default function HarvestList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <DateField source="date_of_harvest" />
        <NumberField source="amount" />
        <ReferenceField
          label="Harvested Product"
          source="harvested_product"
          reference="products"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Harvesting Worker"
          source="harvesting_worker"
          reference="staff"
          link={false}
        >
          <FullnameField source="first_name" />
        </ReferenceField>
        <ReferenceField
          label="Recording Officer"
          source="clerk"
          reference="staff"
          link={false}
        >
          <FullnameField source="first_name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  );
}
