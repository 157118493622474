import React from 'react';
import {
  email,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

export default function StaffEdit(props) {
  return (
    <Edit title="Edit Staff details" {...props}>
      <SimpleForm>
        <TextInput label="First Name" source="first_name" validate={required()} />
        <TextInput label="Last Name" source="last_name" validate={required()} />
        <TextInput label="Email Address" source="email" type="email" validate={email()} />
        <TextInput label="Phone Number" source="phone_number" validate={required()} />
        <SelectInput
          label="Role"
          source="role"
          choices={[
            { id: 'administrator', name: 'Administrator' },
            { id: 'supervisor', name: 'Supervisor' },
            { id: 'farm-worker', name: 'Farm Worker' },
          ]}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
}
