import React from 'react';
import { Admin, Resource, ListGuesser } from 'react-admin';
import UsersIcon from '@material-ui/icons/PermIdentityOutlined';
import StaffIcon from '@material-ui/icons/GroupOutlined';
import HarvestIcon from '@material-ui/icons/ListAltOutlined';

import { authProvider, dataProvider } from './feathersClient/feathersClient';
import DashBoard from './pages/DashBoard';

import StaffCreate from './components/services/staff/StaffCreate';
import StaffEdit from './components/services/staff/StaffEdit';
import StaffList from './components/services/staff/StaffList';

import HarvestCreate from './components/services/harvests/HarvestCreate';
import HarvestList from './components/services/harvests/HarvestList';
import HarvestEdit from './components/services/harvests/HarvestEdit';

import FarmCreate from './components/services/farms/FarmsCreate';
import FarmList from './components/services/farms/FarmsList';

import ProductList from './components/services/products/ProductList';
import ProductCreate from './components/services/products/ProductCreate';

// remember to add rest client options
const App = () => (
  <Admin
    dashboard={DashBoard}
    dataProvider={dataProvider}
    authProvider={authProvider}
    title="Shamba Manager"
  >
    <Resource name="users" icon={UsersIcon} list={ListGuesser} />
    <Resource name="harvests" icon={HarvestIcon} list={HarvestList} create={HarvestCreate} edit={HarvestEdit} />
    <Resource name="products" list={ProductList} create={ProductCreate} />
    <Resource name="staff" icon={StaffIcon} list={StaffList} create={StaffCreate} edit={StaffEdit} options={{ label: 'Staff' }} />
    <Resource name="farms" list={FarmList} create={FarmCreate} />
  </Admin>
);

export default App;
